/* BEM support Func
 -------------------------- */
/* Element Chalk Variables */
/* Transition
-------------------------- */
/* Colors
-------------------------- */
/* 53a8ff */
/* 66b1ff */
/* 79bbff */
/* 8cc5ff */
/* a0cfff */
/* b3d8ff */
/* c6e2ff */
/* d9ecff */
/* ecf5ff */
/* Link
-------------------------- */
/* Background
-------------------------- */
/* Border
-------------------------- */
/* Box-shadow
-------------------------- */
/* Fill
-------------------------- */
/* Font
-------------------------- */
/* Size
-------------------------- */
/* z-index
-------------------------- */
/* Disable base
-------------------------- */
/* Icon
-------------------------- */
/* Checkbox
-------------------------- */
/* Radio
-------------------------- */
/* Select
-------------------------- */
/* Alert
-------------------------- */
/* Message Box
-------------------------- */
/* Message
-------------------------- */
/* Notification
-------------------------- */
/* Input
-------------------------- */
/* Cascader
-------------------------- */
/* Group
-------------------------- */
/* Tab
-------------------------- */
/* Button
-------------------------- */
/* cascader
-------------------------- */
/* Switch
-------------------------- */
/* Dialog
-------------------------- */
/* Table
-------------------------- */
/* Pagination
-------------------------- */
/* Popover
-------------------------- */
/* Tooltip
-------------------------- */
/* Tag
-------------------------- */
/* Tree
-------------------------- */
/* Dropdown
-------------------------- */
/* Badge
-------------------------- */
/* Card
--------------------------*/
/* Slider
--------------------------*/
/* Steps
--------------------------*/
/* Menu
--------------------------*/
/* Rate
--------------------------*/
/* DatePicker
--------------------------*/
/* Loading
--------------------------*/
/* Scrollbar
--------------------------*/
/* Carousel
--------------------------*/
/* Collapse
--------------------------*/
/* Transfer
--------------------------*/
/* Header
  --------------------------*/
/* Footer
--------------------------*/
/* Main
--------------------------*/
/* Break-point
--------------------------*/
/* Break-points
 -------------------------- */
/* Scrollbar
 -------------------------- */
/* Placeholder
 -------------------------- */
/* BEM
 -------------------------- */
/* Element Chalk Variables */
/* Transition
-------------------------- */
/* Colors
-------------------------- */
/* 53a8ff */
/* 66b1ff */
/* 79bbff */
/* 8cc5ff */
/* a0cfff */
/* b3d8ff */
/* c6e2ff */
/* d9ecff */
/* ecf5ff */
/* Link
-------------------------- */
/* Background
-------------------------- */
/* Border
-------------------------- */
/* Box-shadow
-------------------------- */
/* Fill
-------------------------- */
/* Font
-------------------------- */
/* Size
-------------------------- */
/* z-index
-------------------------- */
/* Disable base
-------------------------- */
/* Icon
-------------------------- */
/* Checkbox
-------------------------- */
/* Radio
-------------------------- */
/* Select
-------------------------- */
/* Alert
-------------------------- */
/* Message Box
-------------------------- */
/* Message
-------------------------- */
/* Notification
-------------------------- */
/* Input
-------------------------- */
/* Cascader
-------------------------- */
/* Group
-------------------------- */
/* Tab
-------------------------- */
/* Button
-------------------------- */
/* cascader
-------------------------- */
/* Switch
-------------------------- */
/* Dialog
-------------------------- */
/* Table
-------------------------- */
/* Pagination
-------------------------- */
/* Popover
-------------------------- */
/* Tooltip
-------------------------- */
/* Tag
-------------------------- */
/* Tree
-------------------------- */
/* Dropdown
-------------------------- */
/* Badge
-------------------------- */
/* Card
--------------------------*/
/* Slider
--------------------------*/
/* Steps
--------------------------*/
/* Menu
--------------------------*/
/* Rate
--------------------------*/
/* DatePicker
--------------------------*/
/* Loading
--------------------------*/
/* Scrollbar
--------------------------*/
/* Carousel
--------------------------*/
/* Collapse
--------------------------*/
/* Transfer
--------------------------*/
/* Header
  --------------------------*/
/* Footer
--------------------------*/
/* Main
--------------------------*/
/* Break-point
--------------------------*/
.el-tooltip:focus:not(.focusing), .el-tooltip:focus:hover {
  outline-width: 0; }
.el-tooltip__popper {
  position: absolute;
  border-radius: 4px;
  padding: 10px;
  z-index: 2000;
  font-size: 12px;
  line-height: 1.2; }
.el-tooltip__popper .popper__arrow,
  .el-tooltip__popper .popper__arrow::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid; }
.el-tooltip__popper .popper__arrow {
    border-width: 6px; }
.el-tooltip__popper .popper__arrow::after {
    content: " ";
    border-width: 5px; }
.el-tooltip__popper[x-placement^="top"] {
    margin-bottom: 12px; }
.el-tooltip__popper[x-placement^="top"] .popper__arrow {
    bottom: -6px;
    border-top-color: #303133;
    border-bottom-width: 0; }
.el-tooltip__popper[x-placement^="top"] .popper__arrow::after {
      bottom: 1px;
      margin-left: -5px;
      border-top-color: #303133;
      border-bottom-width: 0; }
.el-tooltip__popper[x-placement^="bottom"] {
    margin-top: 12px; }
.el-tooltip__popper[x-placement^="bottom"] .popper__arrow {
    top: -6px;
    border-top-width: 0;
    border-bottom-color: #303133; }
.el-tooltip__popper[x-placement^="bottom"] .popper__arrow::after {
      top: 1px;
      margin-left: -5px;
      border-top-width: 0;
      border-bottom-color: #303133; }
.el-tooltip__popper[x-placement^="right"] {
    margin-left: 12px; }
.el-tooltip__popper[x-placement^="right"] .popper__arrow {
    left: -6px;
    border-right-color: #303133;
    border-left-width: 0; }
.el-tooltip__popper[x-placement^="right"] .popper__arrow::after {
      bottom: -5px;
      left: 1px;
      border-right-color: #303133;
      border-left-width: 0; }
.el-tooltip__popper[x-placement^="left"] {
    margin-right: 12px; }
.el-tooltip__popper[x-placement^="left"] .popper__arrow {
    right: -6px;
    border-right-width: 0;
    border-left-color: #303133; }
.el-tooltip__popper[x-placement^="left"] .popper__arrow::after {
      right: 1px;
      bottom: -5px;
      margin-left: -5px;
      border-right-width: 0;
      border-left-color: #303133; }
.el-tooltip__popper.is-dark {
    background: #303133;
    color: #fff; }
.el-tooltip__popper.is-light {
    background: #fff;
    border: 1px solid #303133; }
.el-tooltip__popper.is-light[x-placement^="top"] .popper__arrow {
      border-top-color: #303133; }
.el-tooltip__popper.is-light[x-placement^="top"] .popper__arrow::after {
        border-top-color: #fff; }
.el-tooltip__popper.is-light[x-placement^="bottom"] .popper__arrow {
      border-bottom-color: #303133; }
.el-tooltip__popper.is-light[x-placement^="bottom"] .popper__arrow::after {
        border-bottom-color: #fff; }
.el-tooltip__popper.is-light[x-placement^="left"] .popper__arrow {
      border-left-color: #303133; }
.el-tooltip__popper.is-light[x-placement^="left"] .popper__arrow::after {
        border-left-color: #fff; }
.el-tooltip__popper.is-light[x-placement^="right"] .popper__arrow {
      border-right-color: #303133; }
.el-tooltip__popper.is-light[x-placement^="right"] .popper__arrow::after {
        border-right-color: #fff; }
