/* BEM support Func
 -------------------------- */
/* Element Chalk Variables */
/* Transition
-------------------------- */
/* Colors
-------------------------- */
/* 53a8ff */
/* 66b1ff */
/* 79bbff */
/* 8cc5ff */
/* a0cfff */
/* b3d8ff */
/* c6e2ff */
/* d9ecff */
/* ecf5ff */
/* Link
-------------------------- */
/* Background
-------------------------- */
/* Border
-------------------------- */
/* Box-shadow
-------------------------- */
/* Fill
-------------------------- */
/* Font
-------------------------- */
/* Size
-------------------------- */
/* z-index
-------------------------- */
/* Disable base
-------------------------- */
/* Icon
-------------------------- */
/* Checkbox
-------------------------- */
/* Radio
-------------------------- */
/* Select
-------------------------- */
/* Alert
-------------------------- */
/* Message Box
-------------------------- */
/* Message
-------------------------- */
/* Notification
-------------------------- */
/* Input
-------------------------- */
/* Cascader
-------------------------- */
/* Group
-------------------------- */
/* Tab
-------------------------- */
/* Button
-------------------------- */
/* cascader
-------------------------- */
/* Switch
-------------------------- */
/* Dialog
-------------------------- */
/* Table
-------------------------- */
/* Pagination
-------------------------- */
/* Popover
-------------------------- */
/* Tooltip
-------------------------- */
/* Tag
-------------------------- */
/* Tree
-------------------------- */
/* Dropdown
-------------------------- */
/* Badge
-------------------------- */
/* Card
--------------------------*/
/* Slider
--------------------------*/
/* Steps
--------------------------*/
/* Menu
--------------------------*/
/* Rate
--------------------------*/
/* DatePicker
--------------------------*/
/* Loading
--------------------------*/
/* Scrollbar
--------------------------*/
/* Carousel
--------------------------*/
/* Collapse
--------------------------*/
/* Transfer
--------------------------*/
/* Header
  --------------------------*/
/* Footer
--------------------------*/
/* Main
--------------------------*/
/* Break-point
--------------------------*/
/* Break-points
 -------------------------- */
/* Scrollbar
 -------------------------- */
/* Placeholder
 -------------------------- */
/* BEM
 -------------------------- */
/* Element Chalk Variables */
/* Transition
-------------------------- */
/* Colors
-------------------------- */
/* 53a8ff */
/* 66b1ff */
/* 79bbff */
/* 8cc5ff */
/* a0cfff */
/* b3d8ff */
/* c6e2ff */
/* d9ecff */
/* ecf5ff */
/* Link
-------------------------- */
/* Background
-------------------------- */
/* Border
-------------------------- */
/* Box-shadow
-------------------------- */
/* Fill
-------------------------- */
/* Font
-------------------------- */
/* Size
-------------------------- */
/* z-index
-------------------------- */
/* Disable base
-------------------------- */
/* Icon
-------------------------- */
/* Checkbox
-------------------------- */
/* Radio
-------------------------- */
/* Select
-------------------------- */
/* Alert
-------------------------- */
/* Message Box
-------------------------- */
/* Message
-------------------------- */
/* Notification
-------------------------- */
/* Input
-------------------------- */
/* Cascader
-------------------------- */
/* Group
-------------------------- */
/* Tab
-------------------------- */
/* Button
-------------------------- */
/* cascader
-------------------------- */
/* Switch
-------------------------- */
/* Dialog
-------------------------- */
/* Table
-------------------------- */
/* Pagination
-------------------------- */
/* Popover
-------------------------- */
/* Tooltip
-------------------------- */
/* Tag
-------------------------- */
/* Tree
-------------------------- */
/* Dropdown
-------------------------- */
/* Badge
-------------------------- */
/* Card
--------------------------*/
/* Slider
--------------------------*/
/* Steps
--------------------------*/
/* Menu
--------------------------*/
/* Rate
--------------------------*/
/* DatePicker
--------------------------*/
/* Loading
--------------------------*/
/* Scrollbar
--------------------------*/
/* Carousel
--------------------------*/
/* Collapse
--------------------------*/
/* Transfer
--------------------------*/
/* Header
  --------------------------*/
/* Footer
--------------------------*/
/* Main
--------------------------*/
/* Break-point
--------------------------*/
.el-switch {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
  vertical-align: middle; }
.el-switch.is-disabled .el-switch__core,
  .el-switch.is-disabled .el-switch__label {
    cursor: not-allowed; }
.el-switch__label {
    -webkit-transition: .2s;
    transition: .2s;
    height: 20px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    vertical-align: middle;
    color: #303133; }
.el-switch__label.is-active {
      color: #FF850D; }
.el-switch__label--left {
      margin-right: 10px; }
.el-switch__label--right {
      margin-left: 10px; }
.el-switch__label * {
      line-height: 1;
      font-size: 14px;
      display: inline-block; }
.el-switch__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0; }
.el-switch__input:focus ~ .el-switch__core {
      outline: 1px solid #FF850D; }
.el-switch__core {
    margin: 0;
    display: inline-block;
    position: relative;
    width: 40px;
    height: 20px;
    border: 1px solid #dcdfe6;
    outline: none;
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #dcdfe6;
    cursor: pointer;
    -webkit-transition: border-color .3s, background-color .3s;
    transition: border-color .3s, background-color .3s;
    vertical-align: middle; }
.el-switch__core:after {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      border-radius: 100%;
      -webkit-transition: all .3s;
      transition: all .3s;
      width: 16px;
      height: 16px;
      background-color: #fff; }
.el-switch.is-checked .el-switch__core {
    border-color: #FF850D;
    background-color: #FF850D; }
.el-switch.is-checked .el-switch__core::after {
      left: 100%;
      margin-left: -17px; }
.el-switch.is-disabled {
    opacity: 0.6; }
.el-switch--wide .el-switch__label.el-switch__label--left span {
    left: 10px; }
.el-switch--wide .el-switch__label.el-switch__label--right span {
    right: 10px; }
.el-switch .label-fade-enter,
  .el-switch .label-fade-leave-active {
    opacity: 0; }
