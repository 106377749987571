/* BEM support Func
 -------------------------- */
/* Element Chalk Variables */
/* Transition
-------------------------- */
/* Colors
-------------------------- */
/* 53a8ff */
/* 66b1ff */
/* 79bbff */
/* 8cc5ff */
/* a0cfff */
/* b3d8ff */
/* c6e2ff */
/* d9ecff */
/* ecf5ff */
/* Link
-------------------------- */
/* Background
-------------------------- */
/* Border
-------------------------- */
/* Box-shadow
-------------------------- */
/* Fill
-------------------------- */
/* Font
-------------------------- */
/* Size
-------------------------- */
/* z-index
-------------------------- */
/* Disable base
-------------------------- */
/* Icon
-------------------------- */
/* Checkbox
-------------------------- */
/* Radio
-------------------------- */
/* Select
-------------------------- */
/* Alert
-------------------------- */
/* Message Box
-------------------------- */
/* Message
-------------------------- */
/* Notification
-------------------------- */
/* Input
-------------------------- */
/* Cascader
-------------------------- */
/* Group
-------------------------- */
/* Tab
-------------------------- */
/* Button
-------------------------- */
/* cascader
-------------------------- */
/* Switch
-------------------------- */
/* Dialog
-------------------------- */
/* Table
-------------------------- */
/* Pagination
-------------------------- */
/* Popover
-------------------------- */
/* Tooltip
-------------------------- */
/* Tag
-------------------------- */
/* Tree
-------------------------- */
/* Dropdown
-------------------------- */
/* Badge
-------------------------- */
/* Card
--------------------------*/
/* Slider
--------------------------*/
/* Steps
--------------------------*/
/* Menu
--------------------------*/
/* Rate
--------------------------*/
/* DatePicker
--------------------------*/
/* Loading
--------------------------*/
/* Scrollbar
--------------------------*/
/* Carousel
--------------------------*/
/* Collapse
--------------------------*/
/* Transfer
--------------------------*/
/* Header
  --------------------------*/
/* Footer
--------------------------*/
/* Main
--------------------------*/
/* Break-point
--------------------------*/
/* Break-points
 -------------------------- */
/* Scrollbar
 -------------------------- */
/* Placeholder
 -------------------------- */
/* BEM
 -------------------------- */
/* Element Chalk Variables */
/* Transition
-------------------------- */
/* Colors
-------------------------- */
/* 53a8ff */
/* 66b1ff */
/* 79bbff */
/* 8cc5ff */
/* a0cfff */
/* b3d8ff */
/* c6e2ff */
/* d9ecff */
/* ecf5ff */
/* Link
-------------------------- */
/* Background
-------------------------- */
/* Border
-------------------------- */
/* Box-shadow
-------------------------- */
/* Fill
-------------------------- */
/* Font
-------------------------- */
/* Size
-------------------------- */
/* z-index
-------------------------- */
/* Disable base
-------------------------- */
/* Icon
-------------------------- */
/* Checkbox
-------------------------- */
/* Radio
-------------------------- */
/* Select
-------------------------- */
/* Alert
-------------------------- */
/* Message Box
-------------------------- */
/* Message
-------------------------- */
/* Notification
-------------------------- */
/* Input
-------------------------- */
/* Cascader
-------------------------- */
/* Group
-------------------------- */
/* Tab
-------------------------- */
/* Button
-------------------------- */
/* cascader
-------------------------- */
/* Switch
-------------------------- */
/* Dialog
-------------------------- */
/* Table
-------------------------- */
/* Pagination
-------------------------- */
/* Popover
-------------------------- */
/* Tooltip
-------------------------- */
/* Tag
-------------------------- */
/* Tree
-------------------------- */
/* Dropdown
-------------------------- */
/* Badge
-------------------------- */
/* Card
--------------------------*/
/* Slider
--------------------------*/
/* Steps
--------------------------*/
/* Menu
--------------------------*/
/* Rate
--------------------------*/
/* DatePicker
--------------------------*/
/* Loading
--------------------------*/
/* Scrollbar
--------------------------*/
/* Carousel
--------------------------*/
/* Collapse
--------------------------*/
/* Transfer
--------------------------*/
/* Header
  --------------------------*/
/* Footer
--------------------------*/
/* Main
--------------------------*/
/* Break-point
--------------------------*/
.el-select-group {
  margin: 0;
  padding: 0; }
.el-select-group__wrap {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0; }
.el-select-group__wrap:not(:last-of-type) {
      padding-bottom: 24px; }
.el-select-group__wrap:not(:last-of-type)::after {
        content: '';
        position: absolute;
        display: block;
        left: 20px;
        right: 20px;
        bottom: 12px;
        height: 1px;
        background: #e4e7ed; }
.el-select-group__title {
    padding-left: 20px;
    font-size: 12px;
    color: #909399;
    line-height: 30px; }
.el-select-group .el-select-dropdown__item {
    padding-left: 20px; }
