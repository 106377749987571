/* BEM support Func
 -------------------------- */
/* Element Chalk Variables */
/* Transition
-------------------------- */
/* Colors
-------------------------- */
/* 53a8ff */
/* 66b1ff */
/* 79bbff */
/* 8cc5ff */
/* a0cfff */
/* b3d8ff */
/* c6e2ff */
/* d9ecff */
/* ecf5ff */
/* Link
-------------------------- */
/* Background
-------------------------- */
/* Border
-------------------------- */
/* Box-shadow
-------------------------- */
/* Fill
-------------------------- */
/* Font
-------------------------- */
/* Size
-------------------------- */
/* z-index
-------------------------- */
/* Disable base
-------------------------- */
/* Icon
-------------------------- */
/* Checkbox
-------------------------- */
/* Radio
-------------------------- */
/* Select
-------------------------- */
/* Alert
-------------------------- */
/* Message Box
-------------------------- */
/* Message
-------------------------- */
/* Notification
-------------------------- */
/* Input
-------------------------- */
/* Cascader
-------------------------- */
/* Group
-------------------------- */
/* Tab
-------------------------- */
/* Button
-------------------------- */
/* cascader
-------------------------- */
/* Switch
-------------------------- */
/* Dialog
-------------------------- */
/* Table
-------------------------- */
/* Pagination
-------------------------- */
/* Popover
-------------------------- */
/* Tooltip
-------------------------- */
/* Tag
-------------------------- */
/* Tree
-------------------------- */
/* Dropdown
-------------------------- */
/* Badge
-------------------------- */
/* Card
--------------------------*/
/* Slider
--------------------------*/
/* Steps
--------------------------*/
/* Menu
--------------------------*/
/* Rate
--------------------------*/
/* DatePicker
--------------------------*/
/* Loading
--------------------------*/
/* Scrollbar
--------------------------*/
/* Carousel
--------------------------*/
/* Collapse
--------------------------*/
/* Transfer
--------------------------*/
/* Header
  --------------------------*/
/* Footer
--------------------------*/
/* Main
--------------------------*/
/* Break-point
--------------------------*/
/* Break-points
 -------------------------- */
/* Scrollbar
 -------------------------- */
/* Placeholder
 -------------------------- */
/* BEM
 -------------------------- */
/* Element Chalk Variables */
/* Transition
-------------------------- */
/* Colors
-------------------------- */
/* 53a8ff */
/* 66b1ff */
/* 79bbff */
/* 8cc5ff */
/* a0cfff */
/* b3d8ff */
/* c6e2ff */
/* d9ecff */
/* ecf5ff */
/* Link
-------------------------- */
/* Background
-------------------------- */
/* Border
-------------------------- */
/* Box-shadow
-------------------------- */
/* Fill
-------------------------- */
/* Font
-------------------------- */
/* Size
-------------------------- */
/* z-index
-------------------------- */
/* Disable base
-------------------------- */
/* Icon
-------------------------- */
/* Checkbox
-------------------------- */
/* Radio
-------------------------- */
/* Select
-------------------------- */
/* Alert
-------------------------- */
/* Message Box
-------------------------- */
/* Message
-------------------------- */
/* Notification
-------------------------- */
/* Input
-------------------------- */
/* Cascader
-------------------------- */
/* Group
-------------------------- */
/* Tab
-------------------------- */
/* Button
-------------------------- */
/* cascader
-------------------------- */
/* Switch
-------------------------- */
/* Dialog
-------------------------- */
/* Table
-------------------------- */
/* Pagination
-------------------------- */
/* Popover
-------------------------- */
/* Tooltip
-------------------------- */
/* Tag
-------------------------- */
/* Tree
-------------------------- */
/* Dropdown
-------------------------- */
/* Badge
-------------------------- */
/* Card
--------------------------*/
/* Slider
--------------------------*/
/* Steps
--------------------------*/
/* Menu
--------------------------*/
/* Rate
--------------------------*/
/* DatePicker
--------------------------*/
/* Loading
--------------------------*/
/* Scrollbar
--------------------------*/
/* Carousel
--------------------------*/
/* Collapse
--------------------------*/
/* Transfer
--------------------------*/
/* Header
  --------------------------*/
/* Footer
--------------------------*/
/* Main
--------------------------*/
/* Break-point
--------------------------*/
.el-message {
  min-width: 380px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #ebeef5;
  position: fixed;
  left: 50%;
  top: 20px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #edf2fc;
  -webkit-transition: opacity 0.3s, -webkit-transform .4s;
  transition: opacity 0.3s, -webkit-transform .4s;
  transition: opacity 0.3s, transform .4s;
  transition: opacity 0.3s, transform .4s, -webkit-transform .4s;
  overflow: hidden;
  padding: 15px 15px 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
.el-message.is-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
.el-message.is-closable .el-message__content {
    padding-right: 16px; }
.el-message p {
    margin: 0; }
.el-message--info .el-message__content {
    color: #909399; }
.el-message--success {
    background-color: #f0f9eb;
    border-color: #e1f3d8; }
.el-message--success .el-message__content {
      color: #67c23a; }
.el-message--warning {
    background-color: #fdf6ec;
    border-color: #faecd8; }
.el-message--warning .el-message__content {
      color: #e6a23c; }
.el-message--error {
    background-color: #fef0f0;
    border-color: #fde2e2; }
.el-message--error .el-message__content {
      color: #f56c6c; }
.el-message__icon {
    margin-right: 10px; }
.el-message__content {
    padding: 0;
    font-size: 14px;
    line-height: 1; }
.el-message__content:focus {
      outline-width: 0; }
.el-message__closeBtn {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    color: #c0c4cc;
    font-size: 16px; }
.el-message__closeBtn:focus {
      outline-width: 0; }
.el-message__closeBtn:hover {
      color: #909399; }
.el-message .el-icon-success {
    color: #67c23a; }
.el-message .el-icon-error {
    color: #f56c6c; }
.el-message .el-icon-info {
    color: #909399; }
.el-message .el-icon-warning {
    color: #e6a23c; }
.el-message-fade-enter,
.el-message-fade-leave-active {
  opacity: 0;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%); }
